import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SynclisLogo from "../../../assets/images/logo.svg";
import { AuthController } from "../../../service/SynclisService";
import LocalStorageUtility from "../../../utilities/LocalStorageUtility";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const hasSession = user?.sessionToken;
  const isAdmin = user?.role?.key === "admin";

  const handleLogout = () => {
    LocalStorageUtility.delete("synclis_session");
    navigate("/login");
  };

  useEffect(() => {
    const queryUser = async () => {
      const storage = await AuthController.authThroughSession().catch((e) => {
        if (e.status) {
          handleLogout();
        }
      });
      setUser(storage);
    };

    queryUser();
  }, []);

  return (
    <Box
      borderBottom="1px solid #eee"
      display="flex"
      justifyContent="space-between"
      padding="0.25em 1em"
      height="3em"
    >
      <Box display="flex" alignItems="center">
        <Link to="/">
          <img src={SynclisLogo} style={{ width: 140, marginRight: "1em" }} />
        </Link>
        <Link to="/inventory">
          <Box
            borderBottom={
              location.pathname.includes("inventory")
                ? "2px solid #1976d2"
                : "2px solid transparent"
            }
          >
            <Button>Inventory</Button>
          </Box>
        </Link>
      </Box>
      <Box display="flex" alignItems="center">
        {isAdmin && (
          <Link to="/admin/users">
            <Box
              borderBottom={
                location.pathname.includes("admin")
                  ? "2px solid #1976d2"
                  : "2px solid transparent"
              }
            >
              <Button>Admin</Button>
            </Box>
          </Link>
        )}
        {hasSession ? (
          <Button onClick={handleLogout}>Logout</Button>
        ) : (
          <Link to="/login">
            <Box borderBottom="2px solid transparent">
              <Button>Login</Button>
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  );
}
