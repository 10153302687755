export const convertDateJsToTimestamp = (dateJsObject) => {
  const { $y, $M, $D, $H, $m, $s } = dateJsObject;
  const date = new Date();

  date.setFullYear($y);
  date.setMonth($M);
  date.setDate($D);
  date.setHours($H);
  date.setMinutes($m);
  date.setSeconds($s);

  return date.getTime();
};
