import PageWrapper from "../PageWrapper/PageWrapper";
import StorageListingPage from "./StorageListingPage";

export default function Component() {
  return (
    <PageWrapper>
      <StorageListingPage />
    </PageWrapper>
  );
}
