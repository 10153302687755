import React from "react";
import PageWrapper from "../PageWrapper/PageWrapper";
import ParcelsPage from "./ParcelsPage";

export default function Component() {
  return (
    <PageWrapper>
      <ParcelsPage />
    </PageWrapper>
  );
}
