import { useState } from "react";
import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from "@mui/material";
import LargeMovePickupForm from "../../static/LargeMovePickupForm/LargeMovePickupForm";
import SmallMovePickupForm from "../../static/SmallMovePickupForm/SmallMovePickupForm";

export default function RequestStoragePage() {
  const [state, setState] = useState("");
  const [jobSize, setJobSize] = useState("");

  const handleChangeJobSize = (_, jobSize) => {
    setJobSize(jobSize);
  };

  return (
    <>
      <Typography gutterBottom sx={{ color: "text.primary", fontSize: 24 }}>
        Request to store items
      </Typography>
      <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
        How much stuff do you need to move?
      </Typography>
      <Box mb="2em">
        <ToggleButtonGroup
          color="primary"
          value={jobSize}
          exclusive
          onChange={handleChangeJobSize}
          aria-label="Job Size"
        >
          <ToggleButton value="small">A few small items</ToggleButton>
          <ToggleButton value="medium">
            Not too much, but I may need some help
          </ToggleButton>
          <ToggleButton value="large">
            I have a lot of things to move
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {jobSize === "large" && <LargeMovePickupForm />}
      {jobSize === "small" && <SmallMovePickupForm />}
    </>
  );
}
