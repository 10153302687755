import { useState, useEffect } from "react";
import { Box, Button, SwipeableDrawer } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SynclisLogo from "../../../../assets/images/logo.svg";
import SynclisLogoWhite from "../../../../assets/images/logo-white-trans.svg";
import MenuIcon from "@mui/icons-material/Menu";

export default function Header() {
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const links = [
    // { name: "Blog", route: "https://medium.com/@synclis" },
    // { name: "Investors", route: "/investors" },
  ];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const passedGreenScreen = scrollPosition > 400;

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={handleOpenDrawer}
        onOpen={() => {}}
        sx={{ display: { xs: "initial", md: "none" } }}
      >
        <Box p="2em" width="100%" minWidth="300px" boxSizing="border-box">
          {links.map((link) => (
            <Link key={link.name} to={link.route}>
              <Box
                border={
                  location.pathname.includes(link.route)
                    ? "2px solid #24b772"
                    : "2px solid transparent"
                }
              >
                <Button>{link.name}</Button>
              </Box>
            </Link>
          ))}
          <Link to="/login">
            <Box borderBottom="2px solid transparent">
              <Button>Login</Button>
            </Box>
          </Link>
        </Box>
      </SwipeableDrawer>
      <Box
        position="fixed"
        display="flex"
        justifyContent="space-between"
        padding="0.25em 1em"
        height="3.5em"
        zIndex={3}
        width="100%"
        boxSizing="border-box"
        backgroundColor={passedGreenScreen ? "white" : "rgba(255,255,255,0.1)"}
        borderBottom={`1px solid ${passedGreenScreen ? "#eee" : "transparent"}`}
      >
        <Box display="flex" alignItems="center">
          <Link to="/">
            {passedGreenScreen ? (
              <img
                src={SynclisLogo}
                style={{ width: 140, marginRight: "1em" }}
              />
            ) : (
              <img
                src={SynclisLogoWhite}
                style={{ width: 140, marginRight: "1em" }}
              />
            )}
          </Link>
          {links.map((link) => (
            <Link
              key={link.name}
              to={link.route}
              style={{ textDecoration: "none" }}
            >
              <Box
                sx={{ display: { xs: "none", md: "flex" } }}
                borderBottom={
                  location.pathname.includes(link.route)
                    ? "2px solid #24b772"
                    : "2px solid transparent"
                }
              >
                <Button sx={{ color: passedGreenScreen ? "#6ae2aa" : "white" }}>
                  {link.name}
                </Button>
              </Box>
            </Link>
          ))}
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            onClick={handleOpenDrawer}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon sx={{ color: passedGreenScreen ? "#6ae2aa" : "white" }} />
          </Button>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Box
              borderBottom="2px solid transparent"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Button sx={{ color: passedGreenScreen ? "#6ae2aa" : "white" }}>
                Login
              </Button>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
}
