import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";

export default function StorageCard({ item, onClick, selected }) {
  return (
    <Box width="150px" p="4px" style={{ cursor: "pointer" }}>
      <Card raised={selected} onClick={() => onClick(item)}>
        <img
          src="https://place-hold.it/500x500"
          style={{ width: "150px", height: "100px" }}
        />
        <CardContent>
          <Typography sx={{ color: "text.primary", fontSize: 12 }}>
            {item.name}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
