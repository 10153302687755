import { useState, useEffect, forwardRef } from "react";
import { Box, Table, Skeleton, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import { MoveController } from "../../../../service/SynclisService";
import moment from "moment";
import SelectFacilityModal from "../../../modals/SelectFacilityModal/SelectFacilityModal";

const columns = [
  {
    width: 200,
    label: "User",
    dataKey: "customer",
  },
  {
    width: 200,
    label: "State",
    dataKey: "customer.address.state",
  },
  {
    width: 200,
    label: "City",
    dataKey: "customer.address.city",
  },
  {
    width: 120,
    label: "Facility",
    dataKey: "facility",
  },
  {
    width: 120,
    label: "Status",
    dataKey: "status",
  },
  {
    width: 200,
    label: "Requested Date and Time",
    dataKey: "moveDay",
  },
];

const VirtuosoTableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{ backgroundColor: "#eee" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function DeliveriesPage() {
  const [moves, setMoves] = useState(null);
  const [openSelectFacilityModal, setOpenSelectFacilityModal] = useState(false);
  const [currentMove, setCurrentMove] = useState(null);

  useEffect(() => {
    const a = async () => {
      const moves = await MoveController.get();
      setMoves(moves);
    };
    a();
  }, []);

  const toggleOpenSelectFacilityModal = (move) => {
    setCurrentMove(move);
    setOpenSelectFacilityModal(!openSelectFacilityModal);
  };

  const handleSelectFacility = async (facility) => {
    await MoveController.update({
      _id: currentMove._id,
      customer: currentMove.customer,
      moveDay: currentMove.moveDay,
      facility,
    });
  };

  const rowContent = (_index, row) => {
    return (
      <>
        {columns.map((column) => {
          const genContent = () => {
            if (column.dataKey === "moveDay") {
              return moment
                .unix(row[column.dataKey] / 1000)
                .format("MMMM Do YYYY, h:mm:ss a");
            } else if (column.dataKey === "customer") {
              const customer = row[column.dataKey];
              return `${customer.name} (${customer.email})`;
            } else if (column.dataKey === "customer.address.state") {
              const { customer } = row;
              return customer.address.state;
            } else if (column.dataKey === "customer.address.city") {
              const { customer } = row;
              return customer.address.city;
            } else if (column.dataKey === "facility") {
              const facility = row[column.dataKey];
              if (!facility) {
                return (
                  <Button
                    onClick={() => toggleOpenSelectFacilityModal(row)}
                    variant="outlined"
                  >
                    Assign Facility
                  </Button>
                );
              }

              const { addressLine, city, state } = facility.address;
              return `${addressLine} ${city}, ${state}`;
            }

            return row[column.dataKey];
          };

          return (
            <TableCell
              key={column.dataKey}
              align={column.numeric || false ? "right" : "left"}
            >
              {genContent()}
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Paper style={{ height: 400, width: "100%" }}>
        {moves === null && (
          <Box p="1em">
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton />
          </Box>
        )}
        <TableVirtuoso
          data={moves}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Paper>
      <SelectFacilityModal
        isOpen={openSelectFacilityModal}
        onSelect={handleSelectFacility}
        onClose={toggleOpenSelectFacilityModal}
      />
    </>
  );
}
