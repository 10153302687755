import PageWrapper from "../PageWrapper/PageWrapper";
import RequestStoragePage from "./RequestStoragePage";

export default function Component() {
  return (
    <PageWrapper>
      <RequestStoragePage />
    </PageWrapper>
  );
}
