import "./App.css";
import HomePage from "./components/pages/home/HomePage";
import Investors from "./components/pages/home/Investors";
import Login from "./components/pages/auth/LoginPage/LoginPage";
import ForgotPasswordPage from "./components/pages/auth/ForgotPasswordPage/ForgotPasswordPage";
import SetPasswordPage from "./components/pages/auth/SetPasswordPage/SetPasswordPage";
import PageNotFound from "./components/pages/PageNotFound/PageNotFound";
import RequestStoragePage from "./components/pages/RequestStoragePage";
import StorageListingPage from "./components/pages/StorageListingPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StorageRequestsPage from "./components/pages/admin/StorageRequestsPage";
import DeliveriesPage from "./components/pages/admin/DeliveriesPage";
import FacilitiesPage from "./components/pages/admin/FacilitiesPage";
import ParcelsPage from "./components/pages/admin/ParcelsPage";
import RolesPage from "./components/pages/admin/RolesPage";
import UsersPage from "./components/pages/admin/UsersPage";

function App() {
  return (
    <div className="synclis">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="investors" element={<Investors />} />

          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="set-password/:code" element={<SetPasswordPage />} />
          <Route path="inventory" element={<StorageListingPage />} />
          <Route path="request-storage">
            <Route path=":id" element={<RequestStoragePage />} />
          </Route>
          <Route path="admin">
            <Route path="users" element={<UsersPage />} />
            <Route path="roles" element={<RolesPage />} />
            <Route path="facilities" element={<FacilitiesPage />} />
            <Route path="storage" element={<StorageRequestsPage />} />
            <Route path="deliveries" element={<DeliveriesPage />} />
            <Route path="parcels" element={<ParcelsPage />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
