import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PickupVideo from "./pickup.mp4";
import FixedWidthContainer from "../../../reuseable/FixedWidthContainer/FixedWidthContainer";
import MovingIllustration from "./moving.svg";
import RelaxIllustration from "./relax.svg";
import TechnologyIllustration from "./technology.svg";
import TruckIllustration from "./truck.svg";
import ClaypotsImage from "./claypots.jpg";
import "./Homepage.css";

export default function Homepage() {
  return (
    <>
      <Box className="syn-homepage-hero">
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="#6ae2aa"
          sx={{ opacity: "0.5" }}
          zIndex={1}
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)" }}
          zIndex={2}
        >
          <Typography
            letterSpacing={2}
            textTransform="uppercase"
            fontSize={{ xs: "16px", md: "24px" }}
            color="white"
            textAlign="center"
          >
            The future of personal storage
          </Typography>
        </Box>
        <video width="100%" autoPlay muted loop>
          <source src={PickupVideo} type="video/mp4" />
        </video>
        <img src={ClaypotsImage} />
      </Box>

      <Box>
        <Box p="4em">
          <FixedWidthContainer>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                lineHeight: "32px",
                marginBottom: "2em",
                textTransform: "uppercase",
                color: "#555",
                letterSpacing: "2px",
              }}
            >
              Reimagine your space with Synclis
            </Typography>
            <Typography sx={{ lineHeight: "32px" }}>
              Tired of all of the clutter? You can store anything anywhere and
              request for your possessions anytime.
            </Typography>
            <Typography sx={{ lineHeight: "32px" }}>
              We pack, move and deliver your items, organize them, secure them
              in our locations across the country.
            </Typography>
          </FixedWidthContainer>
        </Box>

        <Box p="4em" backgroundColor="#eee" textAlign="center">
          <FixedWidthContainer>
            <Typography
              variant="h6"
              sx={{
                marginBottom: "4em",
                textTransform: "uppercase",
                color: "#555",
                letterSpacing: "2px",
              }}
            >
              What sets us apart?
            </Typography>

            <Grid container spacing={8}>
              <Grid size={{ xs: 12, md: 4 }}>
                <img
                  src={MovingIllustration}
                  style={{
                    maxWidth: "calc(100% - 4em)",
                    marginBottom: "4em",
                    height: "120px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    marginBottom: "2em",
                    textTransform: "uppercase",
                    color: "#555",
                    letterSpacing: "2px",
                  }}
                >
                  Simple
                </Typography>
                <Typography sx={{ lineHeight: "24px" }}>
                  Leave the work to us. Think about the last time you needed to
                  store your items in a personal storage container.
                </Typography>
                <Typography sx={{ lineHeight: "24px" }}>
                  After storing your items into your storage container, getting
                  anything out of it is a near impossible task. With Synclis,
                  you can remain at home and simply request any of your items
                  from the app.
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <img
                  src={RelaxIllustration}
                  style={{
                    maxWidth: "calc(100% - 4em)",
                    marginBottom: "4em",
                    height: "120px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    marginBottom: "2em",
                    textTransform: "uppercase",
                    color: "#555",
                    letterSpacing: "2px",
                  }}
                >
                  Convenient
                </Typography>
                <Typography
                  sx={{
                    lineHeight: "24px",
                  }}
                >
                  Once your items are processed in our storage centers, you can
                  have your items delivered back to you the next day.
                </Typography>
                <Typography
                  sx={{
                    lineHeight: "24px",
                  }}
                >
                  You can add items to your storage from anywhere in the world,
                  you just need to ship them to a registered Synclis storage
                  facility, which you can locate on the app.
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <img
                  src={TechnologyIllustration}
                  style={{
                    maxWidth: "calc(100% - 4em)",
                    height: "120px",
                    marginBottom: "4em",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    marginBottom: "2em",
                    textTransform: "uppercase",
                    color: "#555",
                    letterSpacing: "2px",
                  }}
                >
                  Efficient
                </Typography>
                <Typography sx={{ lineHeight: "24px" }}>
                  Were a modern company, and we're changing the way you look at
                  personal storage.
                </Typography>
                <Typography sx={{ lineHeight: "24px" }}>
                  You can see your items on the Synclis app, and individually
                  request for your items anytime you wish.
                </Typography>
                <Typography sx={{ lineHeight: "24px" }}>
                  The app allows you to have control over your possessions at
                  your fingertips.
                </Typography>
              </Grid>
            </Grid>
          </FixedWidthContainer>
        </Box>
        <Box p="4em">
          <FixedWidthContainer>
            <Grid container spacing={6}>
              <Grid size={{ xs: 12, md: 4 }}>
                <Box padding="2em" textAlign="center">
                  <img
                    src={TruckIllustration}
                    style={{ width: "100%", maxWidth: "200px" }}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 8 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: "2em",
                    letterSpacing: "2px",
                    textTransform: "uppercase",
                    color: "#555",
                  }}
                >
                  Make your next move completely effortless
                </Typography>

                <Typography sx={{ lineHeight: "24px" }}>
                  You also receive the benefits of a traditional moving company.
                </Typography>
                <Typography sx={{ marginBottom: "2em", lineHeight: "24px" }}>
                  We handle all aspects of the move, from packing and loading to
                  transporting and unloading. This saves you time and stress,
                  allowing you to focus on other important aspects of your
                  transition.
                </Typography>

                <Typography sx={{ lineHeight: "24px" }}>
                  Even after you finish your move you can continue to store and
                  retrieve your items with Synclis. You can either book another
                  move with us. Or simply, send items in via UPS, FedEx, or any
                  other shipping provider which you can find through the app.
                </Typography>
              </Grid>
            </Grid>
          </FixedWidthContainer>
        </Box>

        <Box p="4em" backgroundColor="#eee">
          <FixedWidthContainer>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginBottom: "2em",
                textTransform: "uppercase",
                color: "#555",
                letterSpacing: "2px",
              }}
            >
              Whats in store?
            </Typography>

            <Typography>
              In the future, with a premium subscription. Synclis will export
              your items internationally. Next time you're on vacation and you
              need you lucky golf clubs, Synclis will ship them to you before
              your next big golf game.
            </Typography>
          </FixedWidthContainer>
        </Box>
      </Box>
    </>
  );
}
