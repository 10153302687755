import { useState } from "react";
import { Card, Box, TextField, Button, Typography } from "@mui/material";
import SynclisLogo from "../../../../assets/images/logo.svg";

import BackButton from "../../../reuseable/BackButton/BackButton";
import { useNavigate } from "react-router-dom";

export default function ForgotPasswordPage() {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (
      !email ||
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        email
      )
    ) {
      return setError("Email is empty or is an invalid email");
    }
    setError("Unable to send the reset password email. Please contact us.");
  };

  return (
    <>
      <Box p="1em">
        <BackButton onClick={() => navigate("/login")} />
      </Box>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <Card variant="elevation" sx={{ width: 350 }}>
          <Box
            p="1em"
            display="flex"
            flexDirection="column"
            justifyItems="center"
            alignItems="center"
          >
            <img src={SynclisLogo} style={{ width: "200px" }} />
            <Typography
              gutterBottom
              sx={{
                mt: 2,
                color: "text.secondary",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Enter your email to receive
              <br />
              the reset password link.
            </Typography>
            <Box
              backgroundColor="#fcc"
              color="#f44"
              fontSize="12px"
              p="1em"
              width="100%"
              boxSizing="border-box"
              borderRadius="3px"
              marginTop="1em"
              textAlign="center"
              sx={{ opacity: error ? 1 : 0, transition: "opacity 0.3s" }}
            >
              {error}
            </Box>
            <form onSubmit={handleLogin} style={{ width: "100%" }}>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                sx={{ marginBottom: "0.75em", marginTop: "1em", width: "100%" }}
              />
              <Button variant="contained" type="submit" sx={{ width: "100%" }}>
                Reset Password
              </Button>
            </form>
          </Box>
        </Card>
      </Box>
    </>
  );
}
