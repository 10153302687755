import React from "react";
import PageWrapper from "../PageWrapper/PageWrapper";
import FacilitiesPage from "./FacilitiesPage";

export default function Component() {
  return (
    <PageWrapper>
      <FacilitiesPage />
    </PageWrapper>
  );
}
