import * as React from "react";
import { Box, Button } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import "./BackButton.css";

export default function BackButton({ onClick, content = "Go Back" }) {
  return (
    <Button sx={{ padding: 0, margin: 0 }} disableElevation>
      <Box className="syn-back-button" onClick={onClick}>
        <ExpandCircleDownIcon className="chevron-circle-left" />
        <Box className="content">{content}</Box>
      </Box>
    </Button>
  );
}
