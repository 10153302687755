import { useEffect, useState } from "react";
import StorageCard from "../../static/StorageCard/StorageCard";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import CreateStorageModal from "../../modals/CreateStorageModal/CreateStorageModal";
import { StorageController } from "../../../service/SynclisService";
import EmptyBoxes from "./empty-boxes.png";

export default function StorageListingPage() {
  const [storages, setStorages] = useState(null);
  const [currentStorageToDelete, setCurrentStorageToDelete] = useState(null);
  const [isCreateStorageModalOpen, setIsCreateStorageModalOpen] =
    useState(false);

  useEffect(() => {
    const queryStorage = async () => {
      const storage = await StorageController.get().catch((e) => {});
      setStorages(storage);
    };

    queryStorage();
  }, []);

  // const storages = [
  //   {
  //     id: 0,
  //     status: "ACTIVE",
  //     term: "MONTHLY",
  //     size: "SMALL",
  //     createdAt: 1234,
  //     inventory: [
  //       { id: 0, name: "Mattress", createdAt: 123 },
  //       { id: 1, name: "Golf Clubs", createdAt: 123 },
  //       { id: 2, name: "Ski Equipment", createdAt: 123 },
  //       { id: 3, name: "Printer", createdAt: 123 },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     createdAt: 1234,
  //     size: "MEDIUM",
  //     term: "MONTHLY",
  //     status: "CREATING",
  //   },
  // ];

  const handleDelete = (storage) => {
    setCurrentStorageToDelete(storage);
  };

  const handleCloseDeleteModal = () => {
    setCurrentStorageToDelete(null);
  };

  const handleToggleCreateStorageModal = () => {
    setIsCreateStorageModalOpen(!isCreateStorageModalOpen);
  };

  if (!storages?.length) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        textAlign="center"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <img src={EmptyBoxes} style={{ width: "300px" }} />
        <Typography gutterBottom sx={{ color: "text.primary", fontSize: 24 }}>
          It's a bit empty here.
        </Typography>
        <Typography>You currently don't have any storage available</Typography>

        <Button
          onClick={handleToggleCreateStorageModal}
          size="small"
          variant="contained"
          sx={{ marginTop: "1.5em" }}
        >
          Create Storage
        </Button>
        <CreateStorageModal
          onClose={handleToggleCreateStorageModal}
          isOpen={isCreateStorageModalOpen}
        />
      </Box>
    );
  }

  return (
    <Box mb="2em">
      {(storages || []).map((storage) => (
        <StorageCard
          key={storage._id}
          storage={storage}
          onDelete={handleDelete}
        />
      ))}
      <Box px="1em">
        <Button
          onClick={handleToggleCreateStorageModal}
          size="small"
          variant="contained"
          sx={{ marginLeft: "0.5em" }}
        >
          <AddIcon /> Add New Storage
        </Button>
      </Box>

      <ConfirmationModal
        title="Removing Storage"
        subtitle={`You are attempting to remove your ${
          currentStorageToDelete?.size
        } storage. It currently has ${
          currentStorageToDelete?.inventory?.length ?? "no"
        } items. ${
          currentStorageToDelete?.inventory?.length
            ? "They will be shipped back to you. Please ensure that your address is current."
            : ""
        } Once your storage is physically removed it is gone forever.`}
        isOpen={!!currentStorageToDelete}
        confirmText="I&nbsp;Understand"
        onClose={handleCloseDeleteModal}
      />
      <CreateStorageModal
        onClose={handleToggleCreateStorageModal}
        isOpen={isCreateStorageModalOpen}
      />
    </Box>
  );
}
