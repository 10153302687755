import PageWrapper from "../PageWrapper/PageWrapper";
import Homepage from "./HomePage";

export default function Component() {
  return (
    <PageWrapper>
      <Homepage />
    </PageWrapper>
  );
}
