import { Box } from "@mui/material";
import Header from "../Header/Header";

export default function PageWrapper({ children }) {
  return (
    <Box>
      <Header />
      <Box p="2em">{children}</Box>
    </Box>
  );
}
