import * as React from "react";
import { Box } from "@mui/material";

export default function FixedWidthContainer({ children, width = 1000 }) {
  return (
    <Box display="flex" justifyContent="center">
      <Box width={width}>{children}</Box>
    </Box>
  );
}
