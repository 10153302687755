import { Box, Button } from "@mui/material";
import StorageImage from "./storagecontainers.jpg";

export default function Investors() {
  return (
    <Box position="relative" overflow="hidden" height="200px">
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="#6ae2aa"
        sx={{ opacity: "0.5" }}
        zIndex={1}
      />
      <img src={StorageImage} style={{ width: "100%" }} />
    </Box>
  );
}
