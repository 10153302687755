import React from "react";
import PageWrapper from "../PageWrapper/PageWrapper";
import UsersPage from "./UsersPage";

export default function Component() {
  return (
    <PageWrapper>
      <UsersPage />
    </PageWrapper>
  );
}
