import { useState } from "react";
import {
  Card,
  Modal,
  Typography,
  Box,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
} from "@mui/material";
import { StorageController } from "../../../service/SynclisService";

export default function CreateStorageModal({ isOpen, onClose }) {
  const [term, setTerm] = useState("MONTHLY");
  const [size, setSize] = useState("SMALL");
  const [loading, setLoading] = useState(false);

  const handleCreateStorage = async () => {
    setLoading(true);
    await StorageController.create({
      term,
      size,
    });
    onClose();
  };

  const handleDurationChange = (_, duration) => {
    setTerm(duration);
  };

  const handleSetStorageSize = (_, size) => {
    setSize(size);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box position="relative" height="100%">
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <Card>
            <Box p="2em">
              <Typography variant="h6" component="h2">
                Create Long Term Storage
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  mb: "0.75em",
                  fontSize: "14px",
                  color: "text.secondary",
                }}
              >
                Create storage space with Synclis. When we create the space for
                you, you are able to add and remove things from your storage
                anytime you wish.
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={term}
                exclusive
                onChange={handleDurationChange}
                aria-label="Select term"
                sx={{ mb: "1em" }}
              >
                <ToggleButton value="MONTHLY">Monthly</ToggleButton>
                <ToggleButton value="ANNUAL">Annual (20% off)</ToggleButton>
              </ToggleButtonGroup>

              <div>
                <ToggleButtonGroup
                  color="primary"
                  value={size}
                  exclusive
                  onChange={handleSetStorageSize}
                  aria-label="Select storage size"
                >
                  <ToggleButton value="SMALL">
                    <Box>
                      <div>$99.99</div>
                      <div>3 x 3 x 10</div>
                      <Typography
                        sx={{
                          fontSize: 12,
                          textTransform: "none",
                        }}
                      >
                        About the size of a closet
                      </Typography>
                    </Box>
                  </ToggleButton>
                  <ToggleButton value="MEDIUM">
                    <Box>
                      <div>$179.99</div>
                      <div>6 x 6 x 10</div>
                      <Typography
                        sx={{
                          fontSize: 12,
                          textTransform: "none",
                        }}
                      >
                        About the size of a storage closet
                      </Typography>
                    </Box>
                  </ToggleButton>
                  <ToggleButton value="LARGE">
                    <Box>
                      <div>$269.99</div>
                      <div>10 x 10 x 10</div>
                      <Typography
                        sx={{
                          fontSize: 12,
                          textTransform: "none",
                        }}
                      >
                        About the size of a bedroom
                      </Typography>
                    </Box>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Box>
            <Box
              p="2em"
              pt="0"
              display="flex"
              alignItems="right"
              justifyContent="right"
            >
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Box ml="1em">
                <Button
                  onClick={handleCreateStorage}
                  variant="contained"
                  sx={{ minWidth: "100px" }}
                >
                  {loading ? (
                    <CircularProgress size="1.5em" color="inherit" />
                  ) : (
                    "Create Storage"
                  )}
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </Modal>
  );
}
