import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  LinearProgress,
  Typography,
} from "@mui/material";
import StorageCardItem from "../StorageCardItem/StorageCardItem";
import DeleteIcon from "@mui/icons-material/Delete";
import StorageIllustration from "./36118.jpg";
import EmptyBoxesIllustration from "./empty-boxes.png";
import { Link } from "react-router-dom";
import moment from "moment";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearWithValueLabel() {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box display="flex">
      <Typography
        sx={{ color: "text.secondary", fontSize: 12, marginRight: "0.75em" }}
      >
        CAPACITY:
      </Typography>
      <Box width="200px">
        <LinearProgressWithLabel value={progress} />
      </Box>
    </Box>
  );
}

export default function StorageCard({ storage, onDelete }) {
  const [currentItem, setCurrentItem] = useState(null);
  const handleItemClick = (selectedItem) => {
    setCurrentItem(selectedItem);
  };

  const handleDelete = () => {
    onDelete(storage);
  };

  return (
    <Box mb="0.5em">
      <Card variant="outlined">
        <Box
          p="0.75em"
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          borderBottom="1px solid #ddd"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              sx={{
                color: "text.primary",
                fontSize: 20,
                marginBottom: { xs: "1em", md: "0" },
              }}
            >
              {storage.term === "MONTHLY" ? "Monthly" : "Annual"} Storage{" "}
              {storage.size === "SMALL"
                ? "(3 x 3)"
                : storage.size === "MEDIUM"
                ? "(6 x 6)"
                : "(10 x 10)"}
            </Typography>

            <Typography sx={{ color: "text.secondary", fontSize: 12 }}>
              {storage.createdAt ? (
                <span>
                  CREATED AT:{" "}
                  {moment
                    .unix(storage.createdAt / 1000)
                    .format("MMMM Do YYYY, h:mm:ss a")}
                </span>
              ) : (
                "Unknown Creation Time"
              )}
            </Typography>
          </Box>
          {storage.status === "ACTIVE" && <LinearWithValueLabel />}
        </Box>
        <Box display="flex" justifyContent="space-between" p="0.75em">
          <Box
            width="200px"
            display={{ xs: "none", md: "flex" }}
            flexDirection="column"
          >
            {currentItem?.name}
            {currentItem && (
              <img
                src="https://place-hold.it/500x500"
                style={{ width: "150px", height: "100px" }}
              />
            )}
          </Box>
          <Box
            width={{ xs: "100%", md: "calc(100% - 200px)" }}
            display="flex"
            p="0.75em"
          >
            {(storage.inventory || []).map((item) => (
              <StorageCardItem
                selected={currentItem?.id === item.id}
                key={item.id}
                item={item}
                onClick={handleItemClick}
              />
            ))}
            {storage.status === "ACTIVE" && !storage.inventory?.length && (
              <Box
                textAlign="center"
                display="flex"
                width="100%"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Box width="300px">
                  <img
                    src={EmptyBoxesIllustration}
                    style={{ width: "300px", height: "200px" }}
                  />
                  <Typography>
                    Your storage container is empty. Schedule a move by
                    selecting
                    <br />
                    "Add items to storage"
                  </Typography>
                </Box>
              </Box>
            )}
            {storage.status === "CREATING" ||
              (storage.status === "REQUESTED" && (
                <Box
                  textAlign="center"
                  display="flex"
                  width="100%"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Box>
                    <img
                      src={StorageIllustration}
                      style={{ width: "300px", height: "200px" }}
                    />
                    <div>
                      <Typography>
                        {storage.status === "REQUESTED"
                          ? "Your storage container is being prepared."
                          : "We're creating your physical storage space."}
                      </Typography>
                      <Typography
                        sx={{ color: "text.secondary", fontSize: 12 }}
                      >
                        ESTIMATED TIME: 11 HOURS
                      </Typography>
                    </div>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
        <CardActions sx={{ padding: 0 }}>
          <Box borderTop="1px solid #eee" width="100%" p="0.75em">
            <Link to={`/request-storage/${storage._id}`}>
              <Button size="small" variant="outlined">
                Add items to Storage
              </Button>
            </Link>

            {storage.status === "ACTIVE" && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ marginLeft: "0.5em" }}
                >
                  Empty Storage
                </Button>
              </>
            )}
            {storage.status !== "CREATED" && (
              <Button
                size="small"
                variant="text"
                sx={{ marginLeft: "0.5em" }}
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                {storage.status === "REQUESTED"
                  ? "Request Cancellation"
                  : "Remove Storage"}
              </Button>
            )}
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
}
