import { useState, useEffect, forwardRef } from "react";
import { Box, Table, Skeleton, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import { FacilityController } from "../../../../service/SynclisService";
import CreateFacilityModal from "../../../modals/CreateFacilityModal/CreateFaciilityModal";
import moment from "moment";

const columns = [
  {
    width: 200,
    label: "Address",
    dataKey: "address",
  },
  {
    width: 120,
    label: "Size",
    dataKey: "size",
  },
  {
    width: 120,
    label: "Status",
    dataKey: "status",
  },
  {
    width: 120,
    label: "Phone",
    dataKey: "phone",
  },
  {
    width: 200,
    label: "Created at",
    dataKey: "createdAt",
  },
  {
    width: 50,
    label: "Actions",
    dataKey: "actions",
  },
];

const VirtuosoTableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{ backgroundColor: "#eee" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  return (
    <>
      {columns.map((column) => {
        const genContent = () => {
          if (column.dataKey === "createdAt") {
            return moment
              .unix(row[column.dataKey] / 1000)
              .format("MMMM Do YYYY, h:mm:ss a");
          } else if (column.dataKey === "address") {
            const { addressLine, city, state, zipcode } = row[column.dataKey];
            return `${addressLine} ${city}, ${state}, ${zipcode}`;
          }

          return row[column.dataKey];
        };

        return (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? "right" : "left"}
          >
            {genContent()}
          </TableCell>
        );
      })}
    </>
  );
}

export default function FaciltiiesPage() {
  const [facilities, setFacilities] = useState(null);
  const [isCreateFacilityModalOpen, setIsCreateFacilityModalOpen] =
    useState(false);

  const toggleCreateFacilityModal = () => {
    setIsCreateFacilityModalOpen(!isCreateFacilityModalOpen);
  };

  useEffect(() => {
    const a = async () => {
      const facilities = await FacilityController.get();
      setFacilities(facilities);
    };
    a();
  }, []);

  return (
    <>
      <Paper style={{ height: 400, width: "100%" }}>
        {facilities === null && (
          <Box p="1em">
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton />
          </Box>
        )}
        <TableVirtuoso
          data={facilities}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Paper>
      <Button
        onClick={toggleCreateFacilityModal}
        variant="contained"
        sx={{ marginTop: "1.5em" }}
      >
        Create Facility
      </Button>
      <CreateFacilityModal
        onClose={toggleCreateFacilityModal}
        isOpen={isCreateFacilityModalOpen}
      />
    </>
  );
}
