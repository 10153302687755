import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SynclisLogo from "../../../../assets/images/logo.svg";
import SynclisLogoWhite from "../../../../assets/images/logo-white-trans.svg";

export default function Header() {
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const links = [
    { name: "Blog", route: "https://medium.com/@synclis" },
    { name: "Investors", route: "/investors" },
  ];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const passedGreenScreen = scrollPosition > 400;

  return (
    <Box
      position="fixed"
      display="flex"
      justifyContent="space-between"
      padding="0.25em 1em"
      height="3.5em"
      zIndex={3}
      width="100%"
      boxSizing="border-box"
      backgroundColor={passedGreenScreen ? "white" : "rgba(255,255,255,0.1)"}
    >
      <Box display="flex" alignItems="center">
        <Link to="/">
          {passedGreenScreen ? (
            <img src={SynclisLogo} style={{ width: 140, marginRight: "1em" }} />
          ) : (
            <img
              src={SynclisLogoWhite}
              style={{ width: 140, marginRight: "1em" }}
            />
          )}
        </Link>
        {links.map((link) => (
          <Link key={link.name} to={link.route}>
            <Box
              borderBottom={
                location.pathname.includes(link.route)
                  ? "2px solid #1976d2"
                  : "2px solid transparent"
              }
            >
              <Button sx={{ color: passedGreenScreen ? "#6ae2aa" : "white" }}>
                {link.name}
              </Button>
            </Box>
          </Link>
        ))}
      </Box>
      <Box display="flex" alignItems="center">
        <Link to="/login">
          <Box borderBottom="2px solid transparent">
            <Button sx={{ color: passedGreenScreen ? "#6ae2aa" : "white" }}>
              Login
            </Button>
          </Box>
        </Link>
      </Box>
    </Box>
  );
}
