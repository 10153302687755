import { useState } from "react";
import {
  Card,
  Box,
  TextField,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";

import SynclisLogo from "../../../../assets/images/logo.svg";

export default function SetPasswordPage() {
  const [error, setError] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [viewPasswordAgain, setViewPasswordAgain] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const navigate = useNavigate();

  const handleSetPassword = async (e) => {
    e.preventDefault();

    if (password !== passwordAgain) {
      return setError("Passwords do not match");
    }
    navigate("/inventory");
  };

  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      style={{ transform: "translate(-50%, -50%)" }}
    >
      <Card variant="elevation" sx={{ width: 350 }}>
        <Box
          p="1em"
          display="flex"
          flexDirection="column"
          justifyItems="center"
          alignItems="center"
        >
          <img src={SynclisLogo} style={{ width: "200px" }} />
          <Typography
            gutterBottom
            sx={{
              mt: 2,
              color: "text.secondary",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            Link has been verified.
            <br />
            Please reset your password.
          </Typography>
          <Box
            backgroundColor="#fcc"
            color="#f44"
            fontSize="12px"
            p="1em"
            width="100%"
            boxSizing="border-box"
            borderRadius="3px"
            marginTop="1em"
            marginBottom="2em"
            sx={{ opacity: error ? 1 : 0, transition: "opacity 0.3s" }}
          >
            {error}
          </Box>
          <form onSubmit={handleSetPassword} style={{ width: "100%" }}>
            <Box width="100%" position="relative">
              <TextField
                label="Password"
                variant="outlined"
                type={!viewPassword ? "password" : "text"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                sx={{ marginBottom: "0.75em", width: "100%" }}
              />
              <Box
                onClick={() => setViewPassword(!viewPassword)}
                position="absolute"
                zIndex={2}
                top="1em"
                right="1em"
                sx={{ cursor: "pointer", color: "text.secondary" }}
              >
                <Tooltip
                  title={!viewPassword ? "View Password" : "Hide Password"}
                  placement="top"
                >
                  <HelpIcon />
                </Tooltip>
              </Box>
            </Box>
            <Box width="100%" position="relative">
              <TextField
                label="Password Again"
                variant="outlined"
                type={!viewPasswordAgain ? "password" : "text"}
                value={passwordAgain}
                onChange={(e) => {
                  setPasswordAgain(e.target.value);
                }}
                sx={{ marginBottom: "0.75em", width: "100%" }}
              />
              <Box
                onClick={() => setViewPasswordAgain(!viewPasswordAgain)}
                position="absolute"
                zIndex={2}
                top="1em"
                right="1em"
                sx={{ cursor: "pointer", color: "text.secondary" }}
              >
                <Tooltip
                  title={!viewPasswordAgain ? "View Password" : "Hide Password"}
                  placement="top"
                >
                  <HelpIcon />
                </Tooltip>
              </Box>
            </Box>
            <Button variant="contained" type="submit" sx={{ width: "100%" }}>
              Reset Password
            </Button>
          </form>
        </Box>
      </Card>
    </Box>
  );
}
