import { useState } from "react";
import { TextField, Card, Typography, Modal, Box, Button } from "@mui/material";
import { RoleController } from "../../../service/SynclisService";
import _ from "lodash";

export default function CreateRoleModal({ isOpen, onClose, onCreate }) {
  const [role, setRole] = useState({
    key: "",
    name: "",
    description: "",
  });

  const handleCreateRole = async () => {
    const mRole = await RoleController.create(role);
    if (mRole) {
      onCreate(mRole);
    }
  };

  const handleChangeRole = (e) => {
    const { name, value } = e.target;
    const mRole = _.set(role, name, value);
    setRole({
      ...role,
      ...mRole,
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box position="relative" height="100%">
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <Card>
            <Box p="2em">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Create a new Role
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
                Enter the information of the new role.
              </Typography>

              <Box>
                <Box mb="1em">
                  <TextField
                    value={role.key || ""}
                    name="key"
                    onChange={handleChangeRole}
                    label="Key"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb="1em">
                  <TextField
                    value={role.name || ""}
                    name="name"
                    onChange={handleChangeRole}
                    label="Name"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <TextField
                  value={role.description || ""}
                  name="description"
                  onChange={handleChangeRole}
                  multiline
                  rows={3}
                  label="Description"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              p="2em"
              pt="0"
              display="flex"
              alignItems="right"
              justifyContent="right"
            >
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Box ml="1em">
                <Button
                  onClick={handleCreateRole}
                  variant="contained"
                  sx={{ minWidth: "100px" }}
                >
                  Create Role
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </Modal>
  );
}
