import { useState, useEffect, forwardRef } from "react";
import { Box, Table, Skeleton, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import { RoleController } from "../../../../service/SynclisService";
import moment from "moment";
import CreateRoleModal from "../../../modals/CreateRoleModal/CreateRoleModal";

const columns = [
  {
    width: 120,
    label: "Key",
    dataKey: "key",
  },
  {
    width: 200,
    label: "Name",
    dataKey: "name",
  },
  {
    width: 120,
    label: "Description",
    dataKey: "description",
  },
  {
    width: 30,
    label: "Number of Users",
    dataKey: "numUsers",
  },
  {
    width: 50,
    label: "Actions",
    dataKey: "actions",
  },
];

const VirtuosoTableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{ backgroundColor: "#eee" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  return (
    <>
      {columns.map((column) => {
        return (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? "right" : "left"}
          >
            {column.dataKey === "createdAt"
              ? moment
                  .unix(row[column.dataKey] / 1000)
                  .format("MMMM Do YYYY, h:mm:ss a")
              : row[column.dataKey]}
          </TableCell>
        );
      })}
    </>
  );
}

export default function RolesPage() {
  const [roles, setRoles] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const a = async () => {
      const roles = await RoleController.get();
      setRoles(roles);
    };
    a();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCreate = () => {};

  return (
    <>
      <Paper style={{ height: 400, width: "100%" }}>
        {roles === null && (
          <Box p="1em">
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton />
          </Box>
        )}
        <TableVirtuoso
          data={roles}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Paper>
      <Button variant="outlined" onClick={toggleModal}>
        Create Role
      </Button>
      <CreateRoleModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onCreate={handleCreate}
      />
    </>
  );
}
