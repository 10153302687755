/**
 * @Utility LocalStorageUtility
 *
 * @author Chris
 * @since 1.0.0
 *
 * @copyright (c) 2017 Synclis, Inc. All Rights Reserved
 */

//To UPDATE Cookies or LocalStorage, just overwrite the keys.

export default {
  get(key) {
    let nameEQ = `${key}=`;
    let lStore = localStorage.getItem(key);
    let cStore = "";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        cStore = c.substring(nameEQ.length, c.length);
    }
    return cStore ? cStore : lStore ? lStore : null;
  },
  create(key, value, days) {
    let expires = "";

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    } else expires = "";

    localStorage.setItem(key, value);
    document.cookie = `${key}=${value}${expires}; path=/`;
  },
  delete(key) {
    this.create(key, "", -1);
    localStorage.removeItem(key);
  },
};
