// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.syn-back-button {
  display: inline-block;
  vertical-align: middle;
  margin: 0.25em;
  color: #555;
  cursor: pointer;
}

.syn-back-button .chevron-circle-left {
  transition: margin 0.3s, left 0.3s;
  transform: rotate(90deg);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.125em;
  left: 0;
}

.syn-back-button .content {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.75em;
  text-transform: uppercase;
}

.syn-back-button:hover .chevron-circle-left {
  transition: margin 0.3s, left 0.3s;
  margin-right: 0.5em;
  left: -0.125em;
}
`, "",{"version":3,"sources":["webpack://./src/components/reuseable/BackButton/BackButton.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,cAAc;EACd,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,wBAAwB;EACxB,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,qBAAqB;EACrB,OAAO;AACT;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,kCAAkC;EAClC,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".syn-back-button {\n  display: inline-block;\n  vertical-align: middle;\n  margin: 0.25em;\n  color: #555;\n  cursor: pointer;\n}\n\n.syn-back-button .chevron-circle-left {\n  transition: margin 0.3s, left 0.3s;\n  transform: rotate(90deg);\n  position: relative;\n  display: inline-block;\n  vertical-align: middle;\n  margin-right: 0.125em;\n  left: 0;\n}\n\n.syn-back-button .content {\n  display: inline-block;\n  vertical-align: middle;\n  font-size: 0.75em;\n  text-transform: uppercase;\n}\n\n.syn-back-button:hover .chevron-circle-left {\n  transition: margin 0.3s, left 0.3s;\n  margin-right: 0.5em;\n  left: -0.125em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
