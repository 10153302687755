import * as React from "react";
import { Box, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SynclisLogo from "../../../../assets/images/logo.svg";

export default function AdminHeader() {
  const location = useLocation();

  const links = [
    { name: "Users", route: "/admin/users" },
    { name: "Roles", route: "/admin/roles" },
    { name: "Facilties", route: "/admin/facilities" },
    { name: "Storage", route: "/admin/storage" },
    { name: "Deliveries", route: "/admin/deliveries" },
    { name: "Parcels", route: "/admin/parcels" },
  ];

  return (
    <Box
      borderBottom="1px solid #eee"
      display="flex"
      justifyContent="space-between"
      padding="0.25em 1em"
      height="3em"
    >
      <Box display="flex" alignItems="center">
        <Link to="/">
          <img src={SynclisLogo} style={{ width: 140, marginRight: "1em" }} />
        </Link>
        {links.map((link) => (
          <Link key={link.name} to={link.route}>
            <Box
              borderBottom={
                location.pathname.includes(link.route)
                  ? "2px solid #1976d2"
                  : "2px solid transparent"
              }
            >
              <Button>{link.name}</Button>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
}
