import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";

export default function ApplePay({ amount }) {
  const [applePayJsEnabled, setApplePayJsEnabled] = useState(false);
  useEffect(() => {
    // Check if the Apple Pay JS API is available.
    if (window.ApplePaySession) {
      setApplePayJsEnabled(true);
      var merchantIdentifier = "YOUR MERCHANT IDENTIFIER";
      var promise =
        window.ApplePaySession.applePayCapabilities(merchantIdentifier);
      promise.then(function (capabilities) {
        // Check if the person has an active payment credential provisioned in Wallet.
        switch (capabilities.paymentCredentialStatus) {
          case "paymentCredentialsAvailable":
          // Display an Apple Pay button and offer Apple Pay as the primary payment option.
          case "paymentCredentialStatusUnknown":
          // Display an Apple Pay button and offer Apple Pay as a payment option.
          case "paymentCredentialsUnavailable":
          // Consider displaying an Apple Pay button.
          case "applePayUnsupported":
          // Don't show an Apple Pay button or offer Apple Pay.
        }
      });
    }
  }, []);

  const handleClick = async () => {
    // Consider falling back to Apple Pay JS if Payment Request is not available.
    if (!PaymentRequest) {
      return;
    }

    try {
      // Define PaymentMethodData
      const paymentMethodData = [
        {
          supportedMethods: "https://apple.com/apple-pay",
          data: {
            version: 3,
            merchantIdentifier: "merchant.com.synclis",
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: ["amex", "discover", "masterCard", "visa"],
            countryCode: "US",
          },
        },
      ];
      // Define PaymentDetails
      const paymentDetails = {
        total: {
          label: "Synclis, Inc",
          amount: {
            value: amount,
            currency: "USD",
          },
        },
      };
      // Define PaymentOptions
      const paymentOptions = {
        requestPayerName: false,
        requestBillingAddress: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
        requestShipping: false,
        shippingType: "shipping",
      };

      // Create PaymentRequest
      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails,
        paymentOptions
      );

      request.onmerchantvalidation = (event) => {
        console.log(event);
        // Call your own server to request a new merchant session.
        const merchantSessionPromise = fetch(
          `https://api.synclis.com/authorizeApplePayMerchant?validationURL=${event.validationURL}`
        )
          .then((res) => res.json()) // Parse the response as JSON.
          .catch((err) => {
            console.error("Error fetching merchant session", err);
          });

        event.complete(merchantSessionPromise);
      };

      request.onpaymentmethodchange = (event) => {
        if (event.methodDetails.type !== undefined) {
          // Define PaymentDetailsUpdate based on the selected payment method.
          // No updates or errors needed, pass an object with the same total.
          const paymentDetailsUpdate = {
            total: paymentDetails.total,
          };
          event.updateWith(paymentDetailsUpdate);
        }
        // TODO: Support coupons?
        // else if (event.methodDetails.couponCode !== undefined) {
        //   // Define PaymentDetailsUpdate based on the coupon code.
        //   const total = calculateTotal(event.methodDetails.couponCode);
        //   const displayItems = calculateDisplayItem(
        //     event.methodDetails.couponCode
        //   );
        //   const shippingOptions = calculateShippingOptions(
        //     event.methodDetails.couponCode
        //   );
        //   const error = calculateError(event.methodDetails.couponCode);

        //   event.updateWith({
        //     total: total,
        //     displayItems: displayItems,
        //     shippingOptions: shippingOptions,
        //     modifiers: [
        //       {
        //         data: {
        //           additionalShippingMethods: shippingOptions,
        //         },
        //       },
        //     ],
        //     error: error,
        //   });
        // }
      };

      request.onshippingoptionchange = (event) => {
        // Define PaymentDetailsUpdate based on the selected shipping option.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total,
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingaddresschange = (event) => {
        // Define PaymentDetailsUpdate based on a shipping address change.
        const paymentDetailsUpdate = {};
        event.updateWith(paymentDetailsUpdate);
      };

      const response = await request.show();
      const status = "success";
      await response.complete(status);
    } catch (e) {
      // Handle errors
    }
  };

  return (
    <Button
      style={{
        background: "black",
        color: "white",
        minWidth: "150px",
        textTransform: "capitalize",
        fontWeight: "500",
        fontFamily: "Helvetica, Arial, sans-serif",
        height: 34,
      }}
      onClick={handleClick}
    >
      Buy with <AppleIcon fontSize="xsmall" /> Pay
    </Button>
  );
}
