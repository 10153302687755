import { useEffect, useState } from "react";
import {
  TextField,
  Card,
  Typography,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  AuthController,
  MoveController,
  UserController,
} from "../../../service/SynclisService";
import _ from "lodash";
import GeoUtility from "../../../utilities/GeoUtility";
import { convertDateJsToTimestamp } from "../../../utilities/DateUtility";
import Grid from "@mui/material/Grid2";

const { states, countries } = GeoUtility;

export function BasicDateTimePicker({ onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker"]}>
        <DateTimePicker onChange={onChange} label="Pick Up Date" />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default function LargeMovePickupForm() {
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});
  const [move, setMove] = useState({});

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    const mUser = _.set(user, name, value);
    setUser({
      ...user,
      ...mUser,
    });
  };

  const handleChangeMoveDate = (e) => {
    setMove({
      ...move,
      moveDay: convertDateJsToTimestamp(e),
    });
  };

  const handleChangeMove = (e) => {
    const { checked, value } = e.target;
    setMove({
      ...move,
      [value]: checked,
    });
  };

  const handleRequestMove = async () => {
    const mUser = await UserController.update(user);

    const mMove = MoveController.create({
      customer: user._id,
      ...move,
    });
  };

  useEffect(() => {
    const a = async () => {
      const user = await AuthController.authThroughSession();
      setUser(user);
    };
    a();
  }, []);

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontSize: 14 }}
          >
            Personal Information
          </Typography>

          <Grid container spacing={2} sx={{ marginBottom: "1em" }}>
            <Grid size={4}>
              <TextField
                value={user.name || ""}
                name="name"
                label="Name"
                onChange={handleChangeUser}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid size={4}>
              <TextField
                value={user.email || ""}
                name="email"
                onChange={handleChangeUser}
                label="Email"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid size={4}>
              <TextField
                value={user.phone || ""}
                name="phone"
                onChange={handleChangeUser}
                label="Phone"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mb="1em">
            <TextField
              value={user.address?.addressLine || ""}
              name="address.addressLine"
              onChange={handleChangeUser}
              label="Address"
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box mb="1em">
            <TextField
              value={user.address?.addressLine2 || ""}
              name="address.addressLine2"
              onChange={handleChangeUser}
              label="Address Continued"
              variant="outlined"
              fullWidth
            />
          </Box>

          <Grid container spacing={2} sx={{ marginBottom: "1em" }}>
            <Grid size={4}>
              <TextField
                value={user.address?.city || ""}
                name="address.city"
                onChange={handleChangeUser}
                label="City"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid size={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={user.address?.state || ""}
                  name="address.state"
                  label="State"
                  onChange={handleChangeUser}
                >
                  {Object.keys(states).map((state) => (
                    <MenuItem key={state} value={state}>
                      {states[state]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid size={4}>
              <TextField
                value={user.address?.zipcode || ""}
                name="address.zipcode"
                onChange={handleChangeUser}
                label="Zipcode"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select">Country</InputLabel>
            <Select
              labelId="demo-simple-select"
              value={user.address?.country || ""}
              name="address.country"
              label="Country"
              onChange={handleChangeUser}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ marginTop: "2em", marginBottom: "1em" }}>
        <CardContent>
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontSize: 14 }}
          >
            Date to pick up
          </Typography>
          <BasicDateTimePicker onChange={handleChangeMoveDate} />
          <div>
            <FormControlLabel
              value="security"
              control={<Checkbox onChange={handleChangeMove} />}
              label="Are you interested in adding security & surveillance for your items?"
              labelPlacement="end"
            />
          </div>
          <div>
            <FormControlLabel
              value="organization"
              control={<Checkbox onChange={handleChangeMove} />}
              label="Are you interested in a service to organize your items?"
              labelPlacement="end"
            />
          </div>
        </CardContent>
      </Card>
      <Button variant="contained" onClick={handleRequestMove}>
        Request Pick Up
      </Button>
    </>
  );
}
